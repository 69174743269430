<template>
  <div>
    <navtop
      :navList="handleNavList"
      @changeCurrent="
        (e) => {
          queryType = navValue[e];
          resetList(1);
        }
      "
    ></navtop>
    <!-- <div class="row-end screen">
      <div class="search-box row mb20">
        <el-input v-model="keyword" placeholder="请输入内容"></el-input>
        <button type="reset" class="lighter md">订单搜索</button>
      </div>
    </div> -->
    <div class="order-list mb20">
      <div class="order-item item-th row nr lighter">
        <div class="flex5"><span class="pd">商品</span></div>
        <div class="flex1 row-center">数量</div>
        <div class="flex2 row-center">采购金额</div>
        <div class="flex2 row-center">审核状态</div>
        <div class="flex2 row-center">操作</div>
      </div>
      <div
        class="order-item item-pro mb10"
        v-for="(item, index) in purchaseList"
        :key="index"
      >
        <div class="pro-title row-between nr lighter">
          <div class="row ml20">
            <span class="normal mr20">{{ item.create_time }}</span>
            <span class="">采购单号：{{ item.order_sn }}</span>
          </div>
        </div>
        <div
          class="pro-content row"
          v-for="(items, indexs) in item.order_goods"
          :key="indexs"
        >
          <div class="flex5 pro-info row">
            <el-image
              style="width: 64px; height: 64px"
              :src="items.image"
              fit="cover"
              class="ml20 mr20"
            ></el-image>
            <router-link
              :to="`/prodetail?id=${items.goods_id}`"
              class="info-detail flex1 column"
            >
              <p class="name line2">{{ items.goods_name }}</p>
            </router-link>
          </div>
          <div class="flex1 pro-num row-center nr normal">
            X{{ items.goods_num }}
          </div>
          <div class="flex2 row-center">
            <make-priceformat
              :price="items.total_pay_price"
              :subscriptSize="13"
              :firstSize="20"
              :secondSize="20"
            >
            </make-priceformat>
          </div>
          <div class="flex2 row-center">
            <div
              v-if="item.finance_status != '2' && item.manager_status != '2'"
            >
              <span class="cartc" v-if="item.audit_text == ''">审核中</span>
              <span class="primary" v-if="item.audit_text == '已通过'"
                >已通过</span
              >
              <span class="muted" v-if="item.audit_text == '未通过'"
                >未通过</span
              >
            </div>
            <div class="xs price" v-if="item.finance_status == '2'">
              已驳回(财务)
            </div>
            <div class="xs price" v-if="item.manager_status == '2'">
              已驳回(经理)
            </div>
          </div>
          <div class="flex2 row-center">
            <button
              type="primary"
              class="status-btn white row-center"
              @click="
                (auditBox.order_id = item.id),
                  (auditBox.type = 1),
                  (dialogVisible = true)
              "
              v-if="
                item.pay_btn &&
                item.finance_status == '0' &&
                item.manager_status == '1'
              "
            >
              待审核(财务)
            </button>
            <button
              type="primary"
              class="status-btn white row-center"
              @click="
                (auditBox.order_id = item.id),
                  (auditBox.type = 2),
                  (dialogVisible = true)
              "
              v-if="item.pay_btn && item.manager_status == '0'"
            >
              待审核(采购)
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row-end">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
        v-if="purchaseList.length"
      ></make-pagination>
    </div>
    <el-dialog title="订单审核" :visible.sync="dialogVisible" width="30%">
      <el-radio-group v-model="auditBox.status" class="mb20">
        <el-radio :label="1" :value="1">通过审核</el-radio>
        <el-radio :label="2" :value="2">驳回审核</el-radio>
      </el-radio-group>
      <el-input
        v-if="auditBox.status == 2"
        type="textarea"
        :rows="4"
        placeholder="请输入驳回内容（驳回时必填）"
        v-model="auditBox.reason"
        resize="none"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setAudit()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { download } from "@/utils/plugin";
import NavTop from "../components/NavTop";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    navtop: NavTop,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      navList: ["全部", "审核中", "审核通过", "审核不通过"],
      handleNavList: [],
      navValue: ["all", "wait", "pass", "refuse"],
      checked: true,
      checkList: [0, 1],
      keyword: "",
      page_no: 1,
      page_size: 10,
      orderObject: {},
      queryType: "all",
      purchaseList: [],
      dialogVisible: false,
      auditBox: {
        status: 1,
        order_id: "",
        type: "",
        reason: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    async setAudit() {
      let { auditBox } = this;
      let api =
        auditBox.type == 1
          ? this.$api.setFinanceExamineApi
          : this.$api.setManagerExamineApi;
      let { code, msg } = await api(auditBox);
      if (code == 1) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.auditBox.reason = "";
        this.dialogVisible = false;
      }
      this.resetList();
      this.purchaseOrderNumber();
    },
    /**
     * @description 获取售后列表
     * @returns void
     */
    getPurchaseList() {
      this.$api
        .getPurchaseListApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
        })
        .then((res) => {
          this.purchaseList = res.data.list;
          this.orderObject = res.data;
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getPurchaseList();
    },
    purchaseOrderNumber() {
      this.$api.purchaseOrderNumberApi().then((res) => {
        let data = res.data;
        this.handleNavList = this.navValue.map((el, index) => {
          return data[el]
            ? this.navList[index] + `(${data[el]})`
            : this.navList[index];
        });
      });
    },
  },
  created() {
    this.getPurchaseList();
    this.purchaseOrderNumber();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.screen {
  .search-box {
    height: 45px;
    border: 2px solid #c7c7c7;
    background-color: #c7c7c7;

    .el-input {
      width: 262px;
    }

    /deep/ .el-input__inner {
      border: none;
      height: 41px;
      line-height: 1;
      border-radius: 0;
    }

    button {
      height: 41px;
      line-height: 1;
      width: 107px;
      background-color: #c7c7c7;
      cursor: pointer;
    }
  }
}

.order-list {
  overflow: hidden;

  .order-item {
    /deep/ .el-checkbox__label {
      display: none;
    }

    .flex5 {
      flex: 5;
    }

    > div {
      flex-shrink: 0;
      overflow: hidden;
    }

    &.item-th {
      height: 44px;
      background: $-color-center;
      //   padding-right: 25px;
      margin-bottom: 15px;

      .pd {
        padding-left: 56px;
        box-sizing: border-box;
      }
    }

    &.item-pro {
      box-shadow: 0px 1px 0px 0px rgba(184, 184, 184, 0.4);

      .pro-title {
        background-color: $-color-border;
        padding: 6px 0;

        .service {
          margin-right: 36px;

          img {
            width: 30px;
            height: 30px;
            margin-right: 3px;
          }
        }
      }

      .pro-content {
        background-color: $-color-center;

        // padding-right: 25px;
        > div {
          flex-shrink: 0;
          overflow: hidden;
        }

        .pro-info {
          padding: 30px 0;

          .info-detail {
            // padding-right: 20px;
            overflow: hidden;
          }

          img {
            width: 64px;
            height: 64px;
          }
        }

        .pro-price {
          align-items: center;
        }

        .pro-status {
          text-align: center;
        }

        button {
          width: 90px;
          height: 35px;
          cursor: pointer;
        }

        .pro-other {
          align-items: center;

          .time {
            margin-bottom: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

/deep/ .el-dialog__body {
  padding: 20px;

  .el-radio__label {
    font-size: 16px;
  }
}
</style>
